import React from 'react';



const Page: React.FC<{}> = () => {
  return (
    <>
      <h2 style={{textAlign: "center"}}>RCLEC Documents</h2>
      <p><a href={process.env.PUBLIC_URL + "/static/Instructions on how to submit an LSR online V2.pdf"}>Instructions on how to submit a port out request online</a></p>
      <p><a href={process.env.PUBLIC_URL + "/static/RCLEC Trading Partner Profile 20240201.pdf"}>RCLEC Trading Partner Profile</a></p>
      <p><a href={process.env.PUBLIC_URL + "/static/2025 Company Holidays.pdf"}>2025 HOLIDAY SCHEDULE</a></p>
    </>
  );
}

export default Page;
